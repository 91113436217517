import { useEffect, useState } from "react";
import { delay, motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useAudioStore } from "../../AudioStore";

export default function ScreenFinal(){

    const navigate = useNavigate()
    const [isMobile , setIsMobile ] = useState<boolean>(window.innerWidth <= 768);
    const {audio1, inside} = useAudioStore();

    useEffect(()=>{
        
        if(window.location.pathname === '/biriween/final')
        {
            if(inside && audio1){
                inside.pause()
                audio1.currentTime = 0
                setTimeout(() => {
                    audio1.play()
                    
                }, 1000);
              }
            
        }
            
    },[window.location.pathname])

    return (
        <>
            <div className="flex flex-col min-h-svh overflow-hidden relative z-10 animate-fade justify-center items-center bg-center bg-cover" >{/* style={{backgroundImage: `url(${isMobile ? '../assets/biriween/principalshadowmob.png' : '../assets/biriween/principalshadow.png'})`}} */}
                
                <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none animate-fade"  style={{minHeight: '100svh'}} playsInline webkit-playsinline="true">
                    <source src={`${isMobile ? '../assets/biriween/Introloopmob.mp4' : '../assets/biriween/Intoloop.mp4'}`} type="video/mp4" />
                </video>
                <div className="h-svh w-full bg-[#000000b7] z-0 absolute top-0 left-0"></div>
                {<motion.div className="bg-center bg-cover w-full z-10 animate-fade absolute aspect-[9/16] md:aspect-video bottom-0" 
                    style={{backgroundImage: `url(${isMobile ? '../assets/biriween/dialogofinal1mob.png' : '../assets/biriween/dialogofinal1.png'})`}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 1, 1, 1, 0] }} 
                        transition={{
                            opacity: {
                                duration: 4, // Duración total de la animación
                                times: [0, 0.2, 0.4, 0.6, 0.9, 1], // Ajustar la secuencia de tiempos para simular los delays,
                                delay: 2
                            }
                    }}
                    >
                </motion.div>}
                {<motion.div className="bg-center bg-cover w-full z-10 animate-fade absolute aspect-[9/16] md:aspect-video bottom-0" 
                    style={{backgroundImage: `url(${isMobile ? '../assets/biriween/dialogofinal2mob.png' : '../assets/biriween/dialogofinal2.png'})`}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 1, 1, 1, 0] }} 
                        transition={{
                            opacity: {
                                duration: 4, // Duración total de la animació
                                times: [0, 0.2, 0.4, 0.6, 0.9, 1], // Ajustar la secuencia de tiempos para simular los delays
                                delay: 6
                            },
                            
                    }}
                    >
                </motion.div>}
                {<motion.div className="bg-center bg-cover w-full z-10 animate-fade absolute aspect-[9/16] md:aspect-video bottom-0" 
                    style={{backgroundImage: `url(${isMobile ? '../assets/biriween/dialogofinal3mob.png' : '../assets/biriween/dialogofinal3.png'})`}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 1, 1, 1, 0] }} 
                        transition={{
                            opacity: {
                                duration: 4, // Duración total de la animación
                                times: [0, 0.2, 0.4, 0.6, 0.9, 1], // Ajustar la secuencia de tiempos para simular los delays
                                delay: 10
                            },
                            
                    }}
                    >
                </motion.div>}
                {<motion.div className="bg-center bg-cover w-full z-10 animate-fade aspect-[9/16] md:aspect-video bottom-0 absolute" 
                    style={{backgroundImage: `url(${isMobile ? '../assets/biriween/cierremob.png' : '../assets/biriween/cierre.png'})`}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }} 
                        transition={{
                            duration: 2,
                            delay: 14
                            
                    }}
                    >
                        <div className="relative w-full aspect-[9/16] md:aspect-video bottom-0 text-center align-bottom">
                            <div className="flex flex-col absolute left-1/2 md:left-[35%] bottom-[12.5%] md:bottom-[25%] -translate-x-1/2 translate-y-1/2">
                                <p className="font-bloomer text-[#fff] text-xs md:text-lg xl:text-2xl [text-shadow:_0_3px_20px_rgb(0_0_0_/_40%)] animate-fade">¿Quieres jugar de nuevo?</p>
                                <motion.div className="flex flex-row mt-2 md:mt-4 space-x-8 md:space-x-12 justify-center"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        duration: 2,
                                        delay: 16
                                    }}
                                >
                                    
                                    <Link to="/">
                                        <motion.button 
                                            type="submit"
                                            className="cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-xs md:text-sm font-bloomer py-1 px-3 md:py-3 md:px-6 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                            animate={{ scale: [0.8, 1.4, 0.8]}}
                                            transition={{
                                            type: "linear",
                                            duration: 3,
                                            repeat: Infinity
                                            }}
                                        >
                                            No
                                        </motion.button>
                                    </Link>
                                    <Link to="/biriween">
                                        <motion.button 
                                            type="submit"
                                            className="cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-xs md:text-sm font-bloomer py-1 px-3 md:py-3 md:px-6 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                            animate={{ scale: [1.4, 0.8, 1.4]}}
                                            transition={{
                                            type: "linear",
                                            duration: 3,
                                            repeat: Infinity
                                            }}
                                        >
                                            Sí
                                        </motion.button>
                                    </Link>
                                </motion.div>
                            </div>
                        </div>
                        
                </motion.div>}

            </div>

        </>
    )
}