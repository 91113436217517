import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Intro(){

    const [finished, setFinished] = useState(false);
    const [isMobile , setIsMobile ] = useState<boolean>(window.innerWidth <= 768);
    
    return (
        <>
            {!finished && <video preload="metadata" autoPlay muted className="myVideo pointer-events-none animate-fade"  style={{minHeight: '100svh'}} playsInline webkit-playsinline="true" onEnded={() => setFinished(true)}>
                <source src={`${isMobile ? './assets/biriween/Introcabmob.mp4' : './assets/biriween/Intro.mp4'}`} type="video/mp4" />
            </video>}
            {finished && <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none animate-fade"  style={{minHeight: '100svh'}} playsInline webkit-playsinline="true" onEnded={() => setFinished(true)}>
                <source src='./assets/biriween/Introloop.mp4' type="video/mp4" />
            </video>}
            {finished && 
            
                <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-5/6 md:w-3/4 lg:w-1/2 text-center">
                    <motion.img className="w-[95%] mb-4" src="./assets/biriween/brujas.png" alt="" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 2,
                            delay: 2
                        }}
                    />
                    <motion.img className="w-full mb-12" src="./assets/biriween/texto2intro.png" alt="" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 2,
                            delay: 4
                        }}
                    />
                    <motion.img className="w-1/3" src="./assets/biriween/texto3intro.png" alt="" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 2,
                            delay: 6
                        }}
                    />

                    <motion.div className="flex flex-row mt-8 mb-16 space-x-8 md:space-x-12 justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 2,
                            delay: 6
                        }}
                    >
                        <Link to="/">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-sm md:text-xl font-bloomer py-3 md:py-5 px-6 md:px-8 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                animate={{ scale: [0.8, 1.4, 0.8]}}
                                transition={{
                                type: "linear",
                                duration: 3,
                                repeat: Infinity
                                }}
                            >
                                No
                            </motion.button>
                        </Link>
                        <Link to="/biriween/2">
                            <motion.button 
                                type="submit"
                                className="cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-md md:text-2xl font-bloomer py-3 md:py-5 px-6 md:px-8 rounded-full uppercase basis-1/4 opacity-85 transition-all"
                                animate={{ scale: [1.4, 0.8, 1.4]}}
                                transition={{
                                type: "linear",
                                duration: 3,
                                repeat: Infinity
                                }}
                            >
                                Sí
                            </motion.button>
                        </Link>
                    </motion.div> 
                </div>
            
            }

        </>
    )
}