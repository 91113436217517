import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useAudioStore } from "../../AudioStore";
let sound1 = new Audio("../assets/biriween/SFX/1.wav")
let sound2 = new Audio("../assets/biriween/SFX/2.wav")
let sound3 = new Audio("../assets/biriween/SFX/3.wav")
let sound4 = new Audio("../assets/biriween/SFX/4.wav")
let sound5 = new Audio("../assets/biriween/SFX/5.wav")
let sound6 = new Audio("../assets/biriween/SFX/6.wav")
let sound7 = new Audio("../assets/biriween/SFX/7.wav")
let sound8 = new Audio("../assets/biriween/SFX/8.wav")
let sound9 = new Audio("../assets/biriween/SFX/9.wav")
let sound10 = new Audio("../assets/biriween/SFX/10.wav")
let sound11 = new Audio("../assets/biriween/SFX/11.mp3")
let sound12 = new Audio("../assets/biriween/SFX/12.mp3")
let inside = new Audio("../assets/biriween/inside.mp3")

export default function Screen4(){

    const [minigames, setMinigames] = useState({
        1: false,
        2: false,
        3: false
    });
    const navigate = useNavigate()
    const [inGame, setInGame] = useState(false);
    const [tutorial, setTutorial] = useState(true);
    const [currGame, setCurrGame] = useState(0);
    const [gameResp, setGameResp] = useState('')
    const [finalScreen, setFinalScreen] = useState(false);
    const sounds = [sound1, sound2, sound3, sound4, sound5, sound6, sound7, sound8, sound9, sound10, sound11, sound12]
    const [currSound, setCurrSound] = useState(sound1);
    const { setAudio2, audio2, setAudio1, audio1} = useAudioStore();
    const [respsgame2, setRespsgame2] = useState(['Plátano', 'Manzana', 'Frutos Rojos', 'Coco', 'Mango', 'Melón', 'Sandía']);
    const [respsgame3, setRespsgame3] = useState(['1 Cuarto de Boris', '2 Cuarto de Biris', '3 Comedor', '4 Cocina', '5 Baño', '6 Patio frutos rojos', '7 Salón de juegos']);
    const [currResp2, setCurrResp2] = useState('Plátano');
    const [currResp3, setCurrResp3] = useState('1 Cuarto de Boris');
    const [pos, setPos] = useState({top: '0%', left: '0%'})
    const [rot, setRot] = useState('0deg')

    const [isMobile , setIsMobile ] = useState<boolean>(window.innerWidth <= 768);

    // Array de imágenes
    const images = [
        '/assets/biriween/pinwiners1.png',
        '/assets/biriween/pinwiners2.png',
        '/assets/biriween/pinwiners3.png'
        // Agrega más imágenes según sea necesario
    ];

    // Estado para la imagen actual y si está visible
    const [currentImage, setCurrentImage] = useState<string>(images[0]);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        // Cambiar imagen y visibilidad en un intervalo
        const interval = setInterval(() => {
            // Cambiar a una imagen aleatoria
            setCurrentImage(images[Math.floor(Math.random() * images.length)]);
            setPos({top: Math.random()*100 + '%', left: Math.random()*100 + '%'})
            setRot(Math.random()*360 + 'deg')

            setTimeout(() => {
                setIsVisible(true);
            }, 1000);
            
            setTimeout(() => {
                setIsVisible(false)
            }, 2500);

        }, (Math.random() * 5000) + 5000); // Cambia el tiempo (en milisegundos) según lo que necesites

        // Limpieza del intervalo al desmontar
        return () => clearInterval(interval);
    }, [images]);

    useEffect(()=>{
        if(minigames[1] && minigames[2] && minigames[3] && gameResp === ''){
            setTimeout(() => {
                setFinalScreen(true)
            }, 500);
            
        }
        
    },[minigames, gameResp])



    useEffect(() => {
        setAudio2(currSound)
        let s = sounds[Math.floor(Math.random()*sounds.length)]

        if(audio2){
            audio2.currentTime = 0
            audio2.play()
        }
    
        // update the word
        setTimeout(() => setCurrSound(s), 35000);
        
      }, [currSound])

      function getNextResp2(){
        let index = respsgame2.findIndex(x=>x == currResp2)
        index++
        if(index === respsgame2.length) index = 0
        setCurrResp2(respsgame2[index])
      }

      function getPrevResp2(){
        let index = respsgame2.findIndex(x=>x == currResp2)
        index--
        if(index === -1) index = respsgame2.length-1
        setCurrResp2(respsgame2[index])
      }

      function getNextResp3(){
        let index = respsgame3.findIndex(x=>x == currResp3)
        index++
        if(index === respsgame3.length-1) index = 0
        setCurrResp3(respsgame3[index])
      }

      function getPrevResp3(){
        let index = respsgame3.findIndex(x=>x == currResp3)
        index--
        if(index === -1) index = respsgame3.length-1
        setCurrResp3(respsgame3[index])
      }

      function setGood2(){
        setGameResp('good')
        setMinigames({1: minigames[1], 2: true, 3: minigames[3]})
      }

      function setGood3(){
        setGameResp(''); setTutorial(true); setInGame(false)
        setMinigames({1: minigames[1], 2: minigames[2], 3: true})
      }


    return (
        <>

            
            <div className="flex flex-col min-h-svh overflow-hidden relative z-10 animate-fade justify-center items-center overflow-x-hidden bg-center bg-cover" style={{backgroundImage: `url(../assets/biriween/4full.png)`}}>
                <div className="bg-center bg-cover hidden md:block z-11 aspect-video w-full relative overflow-x-hidden overflow-y-clip" > {/* style={{backgroundImage: `url(../assets/biriween/cabana.png)`}} */}
                    
                <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none animate-fade"  style={{height: '100%', width: '100%'}} playsInline webkit-playsinline="true">
                    <source src='../assets/biriween/Cabdentroloop.mp4' type="video/mp4" />
                </video>
                    <motion.img
                        src={currentImage}
                        alt="Imagen aleatoria"
                        className="w-auto h-64 absolute z-30 pointer-events-none"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isVisible ? 1 : 0 }}
                        transition={{ duration: 1 }} // Duración de la animación
                        style={{top: pos.top, left: pos.left, transform: `rotate(${rot})`}}
                    />
                    {(!inGame && gameResp=== '') && <>
                        {!minigames[1] && <motion.button 
                            type="submit"
                            className="absolute top-[35%] right-[11%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-3xl font-bloomer px-[1.3rem] py-[0.50rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{setCurrGame(1); setInGame(true)} }
                        >
                            1
                        </motion.button>}

                        {!minigames[2] && <motion.button 
                            type="submit"
                            className="absolute top-[53%] left-[41%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-3xl font-bloomer px-[1.25rem] py-[0.50rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{if(minigames[1]){setCurrGame(2); setInGame(true)}} }
                        >
                            2
                        </motion.button>}

                        {!minigames[3] && <motion.button 
                            type="submit"
                            className="absolute bottom-[8%] right-[17%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-3xl font-bloomer px-[1.20rem] py-[0.50rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{if(minigames[1] && minigames[2]){setCurrGame(3); setInGame(true)}} }
                        >
                            3
                        </motion.button>}

                    </>}

                    {(inGame || gameResp!== '') &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/4.png')`}}></div>}


                    {gameResp === 'good' &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/respuestacorrecta.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{
                                            setGameResp(''); setTutorial(true); setInGame(false);
                                        }}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div> 
                    </div>}
                    {gameResp === 'bad' &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/respuestaincorrecta.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp(''); setTutorial(false); setInGame(true);}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp(''); setTutorial(true); setInGame(false); setCurrGame(0)}}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div>
                    </div>}

                    {/* GAME 1 ____________________________________________________________________________ */}

                    {inGame && currGame === 1 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game1.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 1 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game1-1.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/1.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/4_.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/10.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('good'); setInGame(false); setMinigames({1: true, 2: minigames[2], 3: minigames[3]})}}
                                        src="../assets/biriween/6.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {/* GAME 2 ____________________________________________________________________________ */}

                    {inGame && currGame === 2 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game2.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 2 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game2-1.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                            className="absolute right-[9%] top-[47%] w-[33%] h-[7%] bg-white border-[#8ceb49] border-2 rounded-md"
                        >
                            <p className="text-[#88219e] text-[3vw] text-center font-amsi leading-[1]">{currResp2}</p>
                        </motion.div>

                        <motion.div className="flex flex-row mb-8 space-x-8 right-1/4 translate-x-1/2 top-[57%] justify-center absolute bottom-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getPrevResp2()}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getNextResp2()}}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div>

                        <motion.div className="flex flex-row mb-8 space-x-8 right-1/4 translate-x-1/2 top-[67%] justify-center absolute bottom-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{currResp2 === 'Frutos Rojos' ? setGood2() : setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/check.png"
                                />
                            </div>

                        </motion.div>

                    </motion.div>}

                    {/* GAME 3 ____________________________________________________________________________ */}

                    {inGame && currGame === 3 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game3.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 3 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game3-1.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                            className="absolute right-[9%] top-[47%] w-[33%] h-[7%] bg-white border-[#8ceb49] border-2 rounded-md"
                        >
                            <p className="text-[#88219e] text-[3vw] text-center font-amsi leading-[1]">{currResp3}</p>
                        </motion.div>

                        <motion.div className="flex flex-row mb-8 space-x-8 right-1/4 translate-x-1/2 top-[57%] justify-center absolute bottom-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getPrevResp3()}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getNextResp3()}}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div>

                        <motion.div className="flex flex-row mb-8 space-x-8 right-1/4 translate-x-1/2 top-[67%] justify-center absolute bottom-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer md:w-10 xl:w-12 2xl:w-14 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{currResp3 === '6 Patio frutos rojos' ? setGood3() : setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/check.png"
                                />
                            </div>

                        </motion.div>

                    </motion.div>}

                    {/* ___________________________________________________________________ */}
                    
                    {finalScreen &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/lograste.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{navigate('/biriween/final')}}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div>
                    </div>}
                    
                </div>
                <div className="bg-center bg-cover block md:hidden z-11 aspect-[9/16] w-full relative">{/* style={{backgroundImage: `url(../assets/biriween/cabanaMob.png)`}} */}
                <video preload="metadata" autoPlay muted loop className="myVideo pointer-events-none animate-fade"  style={{height: '100%', width: '100%'}} playsInline webkit-playsinline="true">
                    <source src='../assets/biriween/Cabdentroloopmob.mp4' type="video/mp4" />
                </video>
                    <motion.img
                        src={currentImage}
                        alt="Imagen aleatoria"
                        className="w-auto h-32 absolute z-30 pointer-events-none"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isVisible ? 1 : 0 }}
                        transition={{ duration: 1 }} // Duración de la animación
                        style={{top: pos.top, left: pos.left, transform: `rotate(${rot})`}}
                    />
                    {(!inGame && gameResp=== '') && <>
                        {!minigames[1] && <motion.button 
                            type="submit"
                            className="absolute top-[25%] right-[32%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-lg font-bloomer px-[0.85rem] py-[0.20rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{setCurrGame(1); setInGame(true)} }
                        >
                            1
                        </motion.button>}

                        {!minigames[2] && <motion.button 
                            type="submit"
                            className="absolute top-[59%] left-[36%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-lg font-bloomer px-[0.85rem] py-[0.20rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{if(minigames[1]){setCurrGame(2); setInGame(true);}} }
                        >
                            2
                        </motion.button>}

                        {!minigames[3] && <motion.button 
                            type="submit"
                            className="absolute bottom-[8%] right-[25%] cursor-pointer bg-[#492185] hover:opacity-100 text-[#8ceb49] border-2 border-[#8ceb49] text-lg font-bloomer px-[0.80rem] py-[0.20rem] rounded-full uppercase opacity-85 transition-all"
                            animate={{ scale: [1.3, 0.8, 1.3]}}
                            transition={{
                            type: "linear",
                            duration: 3,
                                repeat: Infinity
                            }}
                            onClick={() =>{if(minigames[1] && minigames[2]){setCurrGame(3); setInGame(true)}} }
                        >
                            3
                        </motion.button>}
                    </>}

                    {(inGame || gameResp!== '') && <div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/4mob.png')`}}></div>}


                    {gameResp === 'good' &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/respuestacorrectamob.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{
                                            setGameResp(''); setTutorial(true); setInGame(false);
                                        }}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div> 
                    </div>}
                    {gameResp === 'bad' &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/respuestaincorrectamob.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp(''); setTutorial(false); setInGame(true);}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp(''); setTutorial(true); setInGame(false); setCurrGame(0)}}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div>
                    </div>}

                    {/* GAME 1 ____________________________________________________________________________ */}

                    {inGame && currGame === 1 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game1mob.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 1 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game1-1mob.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div className="flex flex-row mb-8 lg:mb-12 space-x-2 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/1.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/4_.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/10.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{setGameResp('good'); setInGame(false); setMinigames({1: true, 2: minigames[2], 3: minigames[3]})}}
                                        src="../assets/biriween/6.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {/* GAME 2 ____________________________________________________________________________ */}

                    {inGame && currGame === 2 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game2mob.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 2 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game2-1mob.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                            className="absolute right-[9%] top-[45%] w-[33%] h-[5%] bg-white border-[#8ceb49] border-2 rounded-md"
                        >
                            <p className="text-[#88219e] text-[5vw] text-center font-amsi leading-[1.3]">{currResp2}</p>
                        </motion.div>

                        <motion.div className="flex flex-row space-x-4 right-[13%] top-[53%] justify-center absolute bottom-24"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getPrevResp2()}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getNextResp2()}}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div>

                        <motion.div className="flex flex-row right-[20%] top-[63%] justify-center absolute bottom-24"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{currResp2 === 'Frutos Rojos' ? setGood2() : setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/check.png"
                                />
                            </div>

                        </motion.div>

                    </motion.div>}

                    {/* GAME 3 ____________________________________________________________________________ */}

                    {inGame && currGame === 3 && tutorial &&<motion.div className="bg-center bg-cover w-full h-full animate-fade z-20 absolute" style={{backgroundImage: `url('../assets/biriween/game3mob.png')`}}
                        initial={{ display: 'none' }}
                            animate={{ display: 'block' }}
                            transition={{
                                delay: 2
                            }}
                    >
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-16 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1.1, 1, 1.1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 2
                                            }
                                            
                                        }}
                                        onClick={()=>setInGame(false)}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        initial={{opacity: 0}}
                                        animate={{ scale: [1, 1.1, 1], opacity: 1}}
                                        transition={{
                                            scale:{
                                                type: "linear",
                                                duration: 3,
                                                repeat: Infinity,
                                                delay:3
                                            },
                                            opacity: {
                                                delay:3,
                                                duration: 1
                                            }
                                            
                                        }}
                                        onClick={()=>setTutorial(false)}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div> 
                    </motion.div>}

                    {inGame && currGame === 3 && !tutorial &&<motion.div className="bg-center bg-cover w-full h-full z-20 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/game3-1mob.png')`}}
                        initial={{ display: 'none' }}
                        animate={{ display: 'block' }}
                        transition={{
                            delay: 0.5
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                            className="absolute right-1/2 translate-x-1/2 top-[71%] w-[70%] h-[5%] bg-white border-[#8ceb49] border-2 rounded-md"
                        >
                            <p className="text-[#88219e] text-[5vw] text-center font-amsi leading-[1.3]">{currResp3}</p>
                        </motion.div>

                        <motion.div className="flex flex-row space-x-4 right-1/2 translate-x-1/2 top-[78%] justify-center absolute bottom-24"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 3
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getPrevResp3()}}
                                        src="../assets/biriween/anterior.png"
                                />
                            </div>
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{getNextResp3()}}
                                        src="../assets/biriween/flecha.png"
                                />
                            </div>

                        </motion.div>

                        <motion.div className="flex flex-row right-1/2 translate-x-1/2 top-[86%] justify-center absolute bottom-24"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50">
                                <motion.img 
                                        className="cursor-pointer w-10 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full"
                                        onClick={()=>{currResp3 === '6 Patio frutos rojos' ? setGood3() : setGameResp('bad'); setInGame(false)}}
                                        src="../assets/biriween/check.png"
                                />
                            </div>

                        </motion.div>

                    </motion.div>}

                    {/* ________________________________________________________________________ */}

                    {finalScreen &&<div className="bg-center bg-cover w-full h-full z-10 animate-fade absolute" style={{backgroundImage: `url('../assets/biriween/lograstemob.png')`}}>
                        <motion.div className="flex flex-row mt-8 mb-8 lg:mb-12 space-x-12 justify-center absolute left-1/2 -translate-x-1/2 bottom-16"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        >
                            <div className="text-center mx-auto w-full z-50 hover:shadow-[0_0px_15px_10px_rgba(135,2,150,0.6)] transition-all rounded-full">
                                <motion.img 
                                        className="cursor-pointer w-12 lg:w-16"
                                        onClick={()=>{navigate('/biriween/final')}}
                                        src="../assets/biriween/cerrar.png"
                                />
                            </div>

                        </motion.div>
                    </div>}

                </div>

            </div>
        </>
    )
}