import * as THREE from "three";
import create from "zustand";
let inside = new Audio("../assets/biriween/inside.mp3")

// Interfaz que define el estado del store
interface AudioStore {
  volume: number;
  audio1: HTMLAudioElement | null;
  audio2: HTMLAudioElement | null;
  inside: HTMLAudioElement | null;
  setVolume: (volume: number) => void;
  setAudio1: (audio: HTMLAudioElement) => void;
  setAudio2: (audio: HTMLAudioElement) => void;
  updateVolume: () => void;
}

// Crear el store de Zustand solo para manejar el audio
export const useAudioStore = create<AudioStore>((set, get) => ({
  volume: 0.2, // Volumen inicial
  audio1: null, // Primer audio
  audio2: null, // Segundo audio
  inside: inside, // Segundo audio

  // Función para cambiar el volumen global
  setVolume: (volume) => {
    set({ volume }); // Primero actualizamos el volumen en el estado
    const { updateVolume } = get();
    updateVolume(); // Después actualizamos el volumen de los audios
  },

  // Función para asignar el primer audio
  setAudio1: (audio) => set({ audio1: audio }),

  // Función para asignar el segundo audio
  setAudio2: (audio) => set({ audio2: audio }),

  // Función que actualiza el volumen de los dos audios
  updateVolume: () => {
    const { volume, audio1, audio2, inside } = get();
    if (audio1) audio1.volume = volume;
    if (audio2) audio2.volume = volume;
    if (inside) inside.volume = volume;
  },
}));