import { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

export default function Screen3(){

    const [finished, setFinished] = useState(false);
    const navigate = useNavigate()
    const [isMobile , setIsMobile ] = useState<boolean>(window.innerWidth <= 768);

    return (
        <>
            {<video preload="metadata" autoPlay muted className="myVideo pointer-events-none animate-fade"  style={{minHeight: '100svh'}} playsInline webkit-playsinline="true" onEnded={() => setFinished(true)}>
                <source src={`${isMobile ? '../assets/biriween/Entercabmob.mp4' : '../assets/biriween/Cabana.mp4'}`} type="video/mp4" />
            </video>}
            {finished && 
                <div className="flex flex-col min-h-svh overflow-hidden relative z-10 animate-fade justify-center items-center bg-center bg-cover" style={{backgroundImage: `url(../assets/biriween/4.png)`}}>
                    
                    <div className="hidden md:block md:w-2/3 xl:w-1/2 text-center">
                        <motion.img className="w-[70%] mb-8" src="../assets/biriween/texto1screen3mob.png" alt="" 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        />
                        <motion.img className="w-full mb-12" src="../assets/biriween/texto2screen3desk.png" alt="" 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        /> 
                    </div>

                    <div className="block md:hidden w-full text-center">
                        <motion.img className="w-[80%] mb-8" src="../assets/biriween/texto1screen3mob.png" alt="" 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 2
                            }}
                        />
                        <motion.img className="w-[60%] mb-12" src="../assets/biriween/texto2screen3mob.png" alt="" 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 2,
                                delay: 4
                            }}
                        /> 
                    </div>

                    <div className="text-center mx-auto w-full z-50">
                            <motion.img 
                                    className="cursor-pointer w-12 lg:w-16"
                                    initial={{opacity: 0}}
                                    animate={{ scale: [1, 1.2, 1], opacity: 1}}
                                    transition={{
                                        scale:{
                                            type: "linear",
                                            duration: 3,
                                            repeat: Infinity,
                                            delay:6
                                        },
                                        opacity: {
                                            delay:6,
                                            duration: 2
                                        }
                                        
                                    }}
                                    onClick={()=>navigate('/biriween/4')}
                                    src="../assets/biriween/flecha.png"
                                />
                    </div>
                </div>
            }

        </>
    )
}