import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/home";
import Header from "./Components/header";
import Step1 from "./Components/step-1";
import Step2 from "./Components/step-2";
import Step3 from "./Components/step-3";
import Step4 from "./Components/step-4";
import Step7 from "./Components/step-7";
import Animation from "./Components/animation";
import Step8 from "./Components/step-8";
import { Suspense} from "react";
import SelectionScreen from "./Components/SelectionScreen";
import Enigma from "./Components/Enigma";
import Intro from "./Components/Biriween/Intro";
import HeaderBiri from "./Components/Biriween/Header";
import Screen2 from "./Components/Biriween/Screen-2";
import Screen3 from "./Components/Biriween/Screen-3";
import Screen4 from "./Components/Biriween/Screen-4";
import ScreenFinal from "./Components/Biriween/Screen-final";



export default function AppRouter(){


    return (

        <>
        <BrowserRouter>
            <Suspense>
                <Routes>
                    <Route path='/' element={<SelectionScreen />} />
                    <Route element={<Header />}>
                        <Route path='celebracion' element={<Home />} />
                        <Route path='celebracion-1' element={<Step1 />} />
                        <Route path='celebracion-2' element={<Step2 />} />
                        <Route path='celebracion-animation' element={<Animation />} />
                        <Route path='celebracion-3' element={<Step3 />} />
                        <Route path='celebracion-4' element={<Step4 />} />
                        <Route path='celebracion-7' element={<Step7 />} />
                        <Route path='celebracion-8' element={<Step8 />} />
                    </Route>
                    <Route path='/enigma' element={<Enigma />} />
                    <Route path='/biriween' element={<HeaderBiri/>} >
                        <Route path='/biriween' element={<Intro />} />
                        <Route path='/biriween/2' element={<Screen2 />} />
                        <Route path='/biriween/3' element={<Screen3 />} />
                        <Route path='/biriween/4' element={<Screen4 />} />
                        <Route path='/biriween/final' element={<ScreenFinal />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>


        </>
    )
}