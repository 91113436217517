import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useAudioStore } from "../../AudioStore";
let soundtrack = new Audio("/assets/biriween/soundtrack.mp3")

export default function HeaderBiri(){

    const [found, setFound] = useState(false);
    const [checkedPN, setCheckedPN] = useState(false);
    const [firstClick, setFirstClick] = useState(false)
    const { setAudio1, audio1, setVolume, volume, inside, audio2} = useAudioStore();

    function handleSubmit(){
        setAudio1(soundtrack);
        setFirstClick(true)
        setVolume(0.2)
        if(checkedPN && audio1){
          audio1.currentTime = 0
          audio1.play()
          audio1.addEventListener('ended', function() {
            this.currentTime = 0;
            this.play();
          }, false);
          setFound(true)
        }
      }

    useEffect(()=>{
        if(window.location.pathname === '/biriween/4')
        {
            if(audio1 && inside){
                audio1.pause()
                audio1.currentTime = 0
                setTimeout(() => {
                    inside.play()
                    inside.addEventListener('ended', function() {
                        this.currentTime = 0;
                        this.play();
                      }, false);
                }, 1000);
              }
            
        }
            
    },[window.location.pathname])

    function mutePage() {
        if(volume == 0)
        {
            if(window.location.pathname === '/biriween/4'){
                inside?.play()
                audio2?.play()
            }else{
                audio1?.play()
            }
            setVolume(0.2)
        }
        else{
            audio1?.pause()
            audio2?.pause()
            inside?.pause()
            setVolume(0)
        }
            
    }

    return (
        <>
            <div className="w-[100vw] h-[100svh] bg-[#80dd4c] absolute top-0 left-0 z-[-1]"></div>
            <header className="-mb-[3.15rem] md:-mb-[3.6rem] lg:-mb-[4.6rem] xl:-mb-[5.55rem] top-0 left-0 right-0 z-[100] px-2 absolute">
                <div className="mx-auto container pt-1">
                    <div className="flex justify-between items-center mx-3 mt-2">
                        <div>
                            <Link to="/"><img className="w-10 lg:w-14 hover:scale-110 transition-all cursor-pointer" src="/assets/biriween/home.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/biriween"><img className="w-20 md:w-24 lg:w-32 xl:w-40" src="/assets/marinela-logo.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/biriween"><img className="w-16 md:w-20 lg:w-24 xl:w-32" src="/assets/pinguinos.png" alt="logotipo"/></Link>
                        </div>
                    </div> 
                </div>
            </header>

            {!found && <>
                <div style={{ height: "100svh", width: "100vw", position: "absolute", backgroundImage: "url(/assets/biriween/principalblur.png)", backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', opacity: 0.5 }} className='animate-fade2'></div>
                    <div style={{ width: '100vw', height: '100svh'}} className='transition-all duration-1000'>
                        <div
                            style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "20px",
                            borderRadius: "10px"
                            }}
                            className="animate-fade w-[70%] md:w-[50%] lg:w-[40%]"
                        >
                            <div className="relative" >

                            <ul className={`justify-center w-full md:w-1/2 mx-auto`}>
                                <li>
                                    <img src="/assets/info.png" alt="" className="w-16 mx-auto animate-ping2"/>
                                    <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer" onChange={() => setCheckedPN(true)} checked={checkedPN} />
                                    <label htmlFor='hosting-big' className={`inline-flex p-1 items-center justify-center w-full my-2 text-gray-50 rounded-xl peer-checked:border-blue-600 peer-checked:text-blue-60 ${!checkedPN && firstClick ? 'outline-dashed outline-2 -outline-offset-1 outline-red-700 bg-[#ff00006e]': 'outline-none'}`}>
                                        <div className="block">
                                            <div className="w-full text-md md:text-md lg:text-2xl font-bloomer">Esta experiencia está diseñada para personas mayores de 18 años. </div>
                                            <div className="w-full text-xs md:text-md lg:text-lg font-bloomer">Para continuar, marca la casilla indicando que eres mayor de edad. </div>
                                            <svg className={`mt-2 mx-auto w-10 h-10 rtl:rotate-180 hover:text-gray-300 hover:scale-110 transition-all cursor-pointer`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" strokeWidth="1.5" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"/>
                                                {checkedPN && <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>}
                                            </svg>
                                        </div>
                                        
                                    </label>
                                </li>
                            </ul>

                            </div>
                            <button
                            onClick={() => handleSubmit()}
                            style={{
                                padding: "10px 20px",
                                fontSize: "20px",
                                cursor: "pointer",
                                backgroundColor: "#492185",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                marginTop: "10px",
                            }}
                            className="animate-wiggle font-bloomer"
                            >
                            Entrar
                            </button>
                        </div>
                    </div>
                </>}

            {found && <div className="w-[100vw] h-[100svh] relative overflow-x-hidden">
                <Outlet />
            </div>}

            <img className="size-8 md:size-10 absolute bottom-10 left-4 z-20 hover:scale-125 transition-all cursor-pointer opacity-70" src={`${volume == 0 ? '/assets/biriween/mute.png' : '/assets/biriween/sound.png'}`} alt="" 
                onClick={() => mutePage()}
            />

            <footer className="w-full z-50 px-0 -mt-9 relative">

                 <div className="flex justify-center items-center mx-3 text-center">
                    <div>
                        <p
                            className="text-[#ffffffad] transition-all text-center text-[0.40rem] md:text-xs -mb-1"
                        >® MARCAS REGISTRADAS; D.R. © 2024 UTILIZADOS
                        BAJO LICENCIA DE SU TITULAR GRUPO BIMBO, S.A.B DE
                        C.V</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mx-3 ">
                        <div>
                        <a href="https://www.bimbo.com.mx/es/terminos-y-condiciones" target="_blank" rel="noreferrer"
                            className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-[0.55rem] md:text-xs"
                        >Términos y condiciones</a>
                        </div>
                        <div>
                            <p
                                className="text-[#ffffffad] transition-all text-center text-[0.55rem] md:text-xs"
                            >"COME BIEN"</p>
                        </div>
                        <div>
                            <a href="https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html" target="_blank" rel="noreferrer"
                                className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-[0.55rem] md:text-xs"
                            >Aviso de privacidad</a>
                        </div>
                </div> 
        
            </footer> 
        </>
    )
}